<template>
    <div v-if="hasAngle" class="relative h-0">
        <div
            class="absolute -top-[43px] h-[86px] w-full"
            :class="'bg-' + bgColor"
        >
            <div
                class="h-full w-full"
                :class="'bg-' + bottomAngleBgColor + ' slanted-' + bottomAngle"
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps({
    bgColor: {
        type: String,
        required: true,
    },
    bottomAngleBgColor: {
        type: String,
        default: null,
    },
    bottomAngle: {
        type: String,
        default: null,
    },
});

const hasAngle = computed<boolean>(() => props.bottomAngle !== 'straight');
</script>
